.w3-table {

    &--no-border {
        border: none;
        tr, tr td {
            border: none;
            padding-left: 0;
        }
    }
}
