.w3-sidebar {
    background: #ffffff;
    @include tablet {
        position: fixed;
        top: 0;
        bottom: 0;
    }

    &__logo {
        width: 100%;
    }

    &__content {
        //padding: 1rem;
        //
        //@include desktop {
        //    padding: 0.5rem;
        //}

        & .menu-label {
            margin-left: 0.5rem;
        }

        & .menu a {
            display: flex;
            align-items: center;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 0.9rem;
            margin: 0.5rem 0;
            border-left: 4px solid transparent;
            color: #4f5d65;

            &.active {
                border-left: 4px solid $w3-color-primary;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            & > i {
                color: rgba(97, 126, 148, 0.76);
                margin-right: 1rem;
            }

            & > .tag {
                margin-left: 0.5rem;
            }

            &:hover {
                background-color: darken(whitesmoke, 20);
            }
        }
    }
}
