
// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

// Variables
@import "variables";

// Helpers
@import "helpers";

// Bootstrap
//@import "~bootstrap/scss/bootstrap";

$family-sans-serif: 'Roboto', sans-serif;

// Bulma + Buefy
@import "~bulma/sass/utilities/initial-variables";
@import "~buefy/src/scss/utils/_all";
@import "~bulma";
@import "~buefy/src/scss/buefy";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/duotone";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";

// Base
@import "base/w3-main";
@import "base/w3-typography";

// Elements
@import "elements/w3-buttons";
@import "elements/w3-cards";
@import "elements/w3-product-price";
@import "elements/w3-badges";
@import "elements/w3-dropdown";
@import "elements/w3-input-fields";
@import "elements/w3-search-box";
@import "elements/w3-overlay";
@import "elements/w3-hero";
@import "elements/w3-indicators";
@import "elements/w3-new-wine";
@import "elements/w3-cart";
@import "elements/w3-delivery-invoice-addresses";
@import "elements/w3-tables";
@import "elements/w3-search";
@import "elements/w3-search__filters";
@import "elements/w3-admin-main-form";
@import "elements/w3-admin-hero";
@import "elements/w3-admin-customers";
@import "elements/w3-checkout";
@import "elements/w3-modals";
@import "elements/w3-order-information";
@import "elements/w3-modal";
@import "elements/w3-product-detail";
@import "elements/w3-regionbar";
@import "elements/w3-dashboard";

// Components
@import "components/w3-sidebar";
@import "components/w3-product-attributes";
@import "components/ais-refinement-list";

// Helpers
@import "helpers";

// External packages
