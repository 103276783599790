.w3-regionbar {
    &__arealogo,
    &__winehouselogo {
        width: auto;
        max-height: 150px;
    }
    &__country {
        font-size: 2rem;
    }
}
