.w3-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 40;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $w3-base-font-color;

    &__background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.57);
    }
}

.w3-modal-card {
    display: flex;
    position: relative;
    background-color: white;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    max-width: 560px;
    border-radius: 5px;
    padding: 0 1rem;

    &__title {
        font-weight: bold;
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    &__product-title {
        margin-bottom: 1.5rem;
        font-weight: bold;
        color: $w3-color-primary;
    }

    &__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }

    &__close-icon {
        font-size: 1.5rem;
    }

    &__image {
        height: 190px;
    }

    &__description-side {
        width: 65%;
        padding: 1rem;
    }

    &__image-side {
        width: 35%;
        padding: 3rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.cart-add-quantity-selector {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.4rem;
    margin-right: -0.4rem;

    &__option {
        width: 50%;
    }

    &__label {
        display: block;
        margin: 0.4rem;
        padding: 0.6rem;
        border-width: 1px;

        &--active {
            color: $w3-color-primary;
            border-color: $w3-color-primary;
        }
    }

    &__oversold{
        padding: 0.6rem 0;
        font-size: .9rem;
    }

}
