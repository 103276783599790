.w3-product-attributes {
    list-style-type: none;
    padding: 0 0;
    line-height: 1.9;

    &__item {

        &__icon {
            width: 25px;
        }
    }
}
