.w3-admin-main-form {
    display: flex;
    flex-direction: column;
    max-width: 968px;
    margin: 0.5rem auto 0;

    &__item {
        border-bottom: 2px solid #e4e4e4;
        display: flex;
    }

    &__label {
        width: 40%;
        margin: 2rem 1rem;
    }

    &__input {
        width: 60%;
        margin: 2rem 1rem;

        & .label {
            font-weight: normal;
        }
    }

    &__title {
        font-weight: bold;
    }

    &__description {
        color: #8e8e8e;
    }

    &.full-width {
        max-width: 100%;
        margin: 0.75rem;
    }

    .table {
        background-color: transparent;
    }
}

.w3-admin-main-form-file-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    &__item {
        width: 33.33%;
        padding: 0.5rem;
    }

    &__item-card {
        background-color: white;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        padding: 0.2rem;
        position: relative;
        height: 140px;
        text-align: center;

        &--h-100 {
            height: 100%;
        }
    }

    &__image-wrapper {
        height: 100%;
        width: 100%;
        position: relative;
    }

    &__delete-button {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    &__image {
        object-fit: contain;
        height: 100%;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
    }

    &__file-thumb {
        padding: 1.3rem 0;
        font-size: 2.8rem;
    }

    &__name {
        word-break: break-word;
        font-size: 0.8rem;
    }

    &__button {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

}

.w3-admin-main-form-search {
}

.w3-admin-main-form-search-results {
    display: flex;
    flex-direction: column;

    &__item {
        background-color: white;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__item-info {
        margin: 1rem;
    }

    &__item-title {
    }

    &__item-icon {
        margin: 1rem;
        color: #cacaca;

        &--active {
            color: #31b737;
        }
    }
}
