.w3-badge {
    padding: 3px 10px;
    border-radius: 20px;
    font-size: 0.7rem;
    font-weight: bold;
    display: inline-block;

    &--primary {
        background-color: $w3-color-primary;
        color: #fff;
    }
    &--secondary {
        background-color: $w3-color-secondary;
        color: #fff;
    }
    &--success {
        background-color: $w3-color-green;
        color: #fff;
    }
    &--warning {
        background-color: $w3-color-yellow;
        color: #fff;
    }
    &--danger {
        background-color: #e62828;
        color: #fff;
    }

    &--block {
        border-radius: 4px;
    }
}
