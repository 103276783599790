.ais-refinement-list {
    &__item {
        &--active {
            font-weight: bold;
            color: $w3-color-primary;
        }
    }
    &__label {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        &:hover {
            color: $w3-color-primary;
        }
    }
    &__checkbox {
        margin-right: 0.5rem;
    }
    &__value {
        flex-grow: 1;
    }
    &__count {
        font-size: 0.75rem;
        &:before {
            content: "(";
        }
        &:after {
            content: ")";
        }
    }
}
