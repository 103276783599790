.w3-input {
    @extend .w3-button;
    @extend .w3-button--primary-outline;

    padding: 8px 16px;
    background: transparent;
    color: $w3-color-primary;

    &--select {
        border-color: #e8e8e8;
         -webkit-appearance: none;
         -moz-appearance: none;
        appearance: none;
        color: $w3-base-font-color;
        font-weight: normal;
        min-width: 170px;
        margin-bottom: 5px;
    }
}
