.w3-admin-hero {
    display: flex;
    padding: 3rem 2rem;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

    &__title {
        font-size: 2rem;
    }

    &__button {

    }
}
