.w3-search-pagination {
    display: flex;
    justify-content: center;

    .ais-pagination__item {
        margin: 0.7rem;
        background-color: white;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        display: flex;

        @media (max-device-width : $tablet) {
            margin: 0.6rem 0.2rem;
        }

        &:hover {
            background-color: darken(white, 10);
        }

        & > .ais-pagination__link {
            color: inherit;
        }

        &--active {
            background-color: $primary;
            color: white;

            &:hover {
                background-color: lighten($primary, 25);
            }
        }
    }

    .ais-pagination__link {
        padding: 0.4rem 0.8rem;

        @media (max-device-width : $tablet) {
            padding: 0.4rem;
        }
    }


}

.w3-search-current-filter {
    display: flex;

    &__badge {
        margin-right: 0.3rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0.3rem;

        &:hover {
            background-color: #1C4E74;
        }
    }

    &__text {
        line-height: 1;
        margin-right: 0.2rem;
    }
}

.w3-search-amount-tag {
    cursor: pointer;

    &__icon {
        margin-right: 0.3rem;
    }
}

.w3-search-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-results {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: -1.5rem;

    &__item {
        padding: 1.5rem;
    }
}
