.w3-search-box {
    display: inline-flex;
    position: relative;
    @include mobile {
        display: block;
    }

    .ais-search-box__submit,
    .ais-clear {
        display: none;
    }

    .ais-input {
        @extend .w3-button;
        @extend .w3-button--primary-outline;

        background: transparent;
        color: $w3-color-primary;
        padding: 8px 16px 8px 30px;

        @include mobile {
            width: 100%;
        }

        &::placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::-webkit-input-placeholder {
            color: $w3-color-primary;
            opacity: 1;
        }
    }

    button {
        @extend .w3-button;
        @extend .w3-button--primary-outline;

        &.ais-clear {
            display: none;
        }
        &.ais-search-box__submit {
            position: absolute;
            right: 5px;
            top: 3px;
            bottom: 2px;
            border: none;
        }
    }

}

.w3-search-box-group {
    position: relative;

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 11px;
    }
}
