.w3-overlay {

    &--product-list {
        background: $w3-body-bgcolor;
        opacity: 0.7;
        z-index: 1;

        @media (max-device-width : $tablet) {
            display: none;
        }
    }
}
