.w3-checkout {
    padding: 2rem;

    &__title {
        margin-bottom: 2rem;
    }

    .w3-header {
        margin-bottom: 1rem;
    }
}

.w3-checkout-confirmation {
    padding: 2rem;

    &__title {
        margin-bottom: 2rem;
    }
}




