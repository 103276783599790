html{
    height: 100%;
    font-size: $w3-base-font-size;
}
.w3-body {
    background: $w3-body-bgcolor;
    min-height: 100%;
}
.w3-main {
    min-height: 100vh;

    &-content {
        padding: 40px;

        @include mobile {
            padding: 10px;
        }
    }

    .product-list {
        position: relative;
    }

    &__section {
        margin-left: auto !important;
    }
}
