.w3-dashboard-loyalty {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__points {
        font-size: 6rem;
        color: $w3-color-primary;
    }
}
