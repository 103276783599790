.w3-card {
    @extend .card;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    overflow: hidden;

    &--rounded {
        border-radius: 8px;
    }
    &--content {
        padding: 20px;
    }
    &--eq-height {
        height: 100%;
    }

    &--product {
        font-size: 0.9rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__icons {
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            font-size: 1.2rem;

            .fa-tint {
                float: right;
                //color: $w3-color-yellow;
            }
        }
        &__add-to-cart-icon {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
        &__heart-icon {
            color: #CFD7DD;
            cursor: pointer;

            &:hover {
                color: #EBA0A9;
            }

            &--active {
                color: #FF3860;
            }
        }
        &__img {
            max-width: 100%;
            height: 180px;
            margin: 10px auto;
            display: block;
        }
        &__title-block {
            flex-grow: 1;
            border-top: 1px solid #ededed;
            padding: 10px 5px 0 15px;
            margin: 10px 5px 0px;
        }
        &__content {
            padding: 0px 5px 10px;
            margin: 0 5px 0 15px;

            .w3-badge {
                margin-top: 4px;
            }
        }
        &__buttons {
            padding: 10px;
            background-color: $w3-color-lightgray;
            text-align: center;

            @include until($widescreen) {
                .w3-button {
                    display: block;
                    margin-bottom: 5px;
                }
            }
        }

        &--in-cart {
            .w3-product-price {
                margin-top: 10px;
            }
        }
    }

    &--new-wine {
        background: url('/img/bg-stars.png') left bottom no-repeat #fff;
    }

    &--contact-person {
        &__image {
            display: block;
            height: 100%;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            background-position: top center;
            background-size: cover;
        }
    }
}
