.w3-cart {
    &__items {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
    }

    &__empty {
        &__box {
            .text {
                font-size: 1.5rem;
            }
        }
    }
}

.w3-cart-item {
    background: rgb(255, 255, 255);
    display: flex;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;

    &__thumbnail {
        width: 10%;
        margin-right: 1rem;
        position: relative;

        & > .w3-cart-item__image {
            position: absolute;
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    &__quantity {
        display: flex;
        align-items: center;
        & > .label {
            margin-bottom: 0;
            margin-right: 1rem;
        }
    }

    &__title {

    }

    &__reference {
        margin-bottom: 1rem;
    }

    &__info {
        flex: 1;
    }

    &__price {
        display: flex;
        align-items: center;
        margin-right: 2rem;
        font-size: 2rem;
    }

    &__delete-item {
        display: flex;
        align-items: center;
    }
}

.w3-cart-totals {
    .price-total-box {
        margin-bottom: 2rem;
        background: rgb(255, 255, 255);
        padding: 1rem;
        border-radius: 5px;

        .price-row {
            padding-bottom: 0.5rem;

            .price-value {
                float: right;
            }

            &.big {
                .price-label {
                    font-weight: 600;
                }

                .price-value {
                    font-weight: 600;
                }
            }

            &.biggest {
                padding-bottom: 0;

                .price-label {
                    font-weight: 600;
                    font-size:1.5rem;
                }

                .price-value {
                    font-size:1.5rem;
                    font-weight: 600;
                }
            }

            &.total {
                border-bottom: 1px solid $w3-color-primary;
                margin-bottom: 1rem;
            }
        }
    }
}
