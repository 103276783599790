.w3-product-detail {

}

.w3-product-detail-images {
    &__main {
        object-fit: contain;
        height: 400px;
    }

    &__main-image {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    &__thumbnails {
        display: flex;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    &__thumbnail {
        width: 20%;
        margin-bottom: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        height: 100px;
        cursor: pointer;
    }

    &__thumbnail-image {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.w3-product-detail-wine-house {
    display: flex;
    overflow: hidden;
    @include mobile {
        flex-direction: column-reverse;
    }

    &__text-side {
        width: 50%;
        padding: 2rem;

        @include mobile {
            width: 100%;
        }
    }

    &__description {
        display: flex;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
    }

    &__description-text {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    &__visual-side {
        width: 50%;

        @include mobile {
            width: 100%;
        }
    }

    &__image {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}
