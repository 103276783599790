.w3-search__filters {
    @include mobile {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #fff;
        z-index: 99999;
        padding: 20px;
    }

    .title {
        @include tablet {
            display: none;
        }
    }
}
.w3-search__open-filters {
    @include tablet {
        display: none;
    }
}

.w3-search-filters {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        @media (min-device-width: $tablet) {
            display: none;
        }
    }

    &__close {

    }

    &__close-icon {
        font-size: 2rem;
    }
}
