.w3-modals {
    .modal-content {
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.4);
    }

    .modal-background {
        background: rgba(244, 247, 247, 0.8);
    }

    &__close-icon {
        position: absolute;
        top: 20px;
        right: 10px;
        z-index: 1;
        text-align: center;

        i {
            color: $w3-color-primary;
            width: 40px;
            height: 22px;
            cursor: pointer;
            font-size: 22px;
        }
    }
}
