.w3-hero {
    @extend .hero;
    color: #fff;

    &--dashboard {
        background: url('/img/druiven-example.jpg');
        padding-bottom: 1rem;
        margin-bottom: -4.5rem;
    }

    &-body {
        padding: 3rem 40px;
    }
}
