$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 125, 150); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px!important;
        }

        .p-#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px!important;
        }
    }
}

.is-marginless {
    &--top {
        margin-top: 0 !important;
    }
    &--right {
        margin-right: 0 !important;
    }
    &--bottom {
        margin-bottom: 0 !important;
    }
    &--left {
        margin-left: 0 !important;
    }
}

.is-full-height { height: 100%; }
.is-full-width { width: 100%; }

// Reset input search type
input[type="search"] {
    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
