.w3-delivery-addresses {

}

.w3-delivery-address-row,
.w3-invoice-addresses {
    margin-bottom: 1rem;
    position: relative;
    cursor: pointer;
    padding: 10px;
    border: 2px solid #eee;
    border-radius: 6px;
    user-select: none;

    &.active {
        border: 2px solid $w3-color-primary;

        .address-box {
            .postcode-city,
            .country {
                display: block;
            }
        }
    }

    .radio-button {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #eee;
        transition: background .15s ease-out;

        &:before {
            content: '';
            width: 10px;
            height: 10px;
            top: 3px;
            left: 3px;
            position: absolute;
            background-color: $w3-color-primary;
            border-radius: 50%;
            transform: scale(0);
            transition: transform .15s ease-out;
        }


        &.active {
            border: 2px solid $w3-color-primary;

            &:before {
                transform: scale(1);
            }
        }
    }

    .address-box {
        padding-left: 30px;

        .fullname {
            font-weight: 600;
        }

        .postcode-city,
        .country {
            display: none;
        }
    }

    .edit-address {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
    .delete-address {
        position: absolute;
        right: 35px;
        top: 11px;
        cursor: pointer;
        font-size: 14px;
    }
}

.w3-edit-address-modal {
    .card-content {
        position: relative;

        .delete-address-link {
            position: absolute;
            right: 1.5rem;
            bottom: 1.8rem;
            font-size: 14px;
            color: $w3-color-primary;
        }
    }
}
