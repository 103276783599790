.w3-new-wine {
    &__attribute {
        &-label {
            min-width: 115px;
            display: inline-block;
            @include mobile {
                min-width: 140px;
            }
        }
    }
}
