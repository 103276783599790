.w3-indicator {
    width: 10px;
    height: 10px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 100%;

    &--success {
        background-color: $w3-color-success;
    }
    &--warning {
        background-color: $w3-color-warning;
    }
    &--danger {
        background-color: $w3-color-danger;
    }

    &--small {
        width: 8px;
        height: 8px;
    }
    &--big {
        width: 15px;
        height: 15px;
    }
}
