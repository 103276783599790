.w3-product-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: $w3-color-secondary;
    display: block;
    margin-top: 35px;

    &__option {
        font-size: 0.9rem;
        font-weight: normal;
        color: #4a4a4a;
    }
}
