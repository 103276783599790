.w3-button {
    border-radius: 4px;
    display: inline-block;
    font-weight: bold;
    padding: 3px 8px;
    position: relative;
    top: 0;
    transition-duration: 100ms;
    font-size: 0.9rem;
    cursor: pointer;
    line-height: 1.5;

    &:hover {
        background: lighten($w3-color-primary,10%);
        border-color: lighten($w3-color-primary,10%);
        color: #ffffff;
    }

    &--primary {
        background: $w3-color-primary;
        border: 2px solid $w3-color-primary;
        color: $white;

        &:hover {
            background: lighten($w3-color-primary,10%);
            border-color: lighten($w3-color-primary,10%);
            color: #ffffff;
        }
    }

    &--primary-outline {
        background: none;
        border: 2px solid $w3-color-primary;
        color: $w3-color-primary;

        &:hover {
            background: #ffffff;
            color: lighten($w3-color-primary,10%);
        }
    }

    &--disabled {
        background-color: #e8e8e8;
        border: 2px solid #e8e8e8;
        color: grey;
        cursor: not-allowed;

        &:hover {
            background: #e8e8e8;
            color: grey;
            border-color: #e8e8e8;
        }
    }

    &--secondary {
        background: $w3-color-secondary;
        border: 2px solid $w3-color-secondary;
        color: $white;

        &:hover {
            background: lighten($w3-color-secondary,10%);
            border-color: lighten($w3-color-secondary,10%);
            color: $white;
        }
    }

    &--secondary-outline {
        background: none;
        border: 2px solid $w3-color-secondary;
        color: $w3-color-secondary;

        &:hover {
            background: none;
            color: lighten($w3-color-secondary,10%);
            border-color: lighten($w3-color-secondary,10%);
        }
    }

    //&--tertiary {
    //    background: $w3-color-tertiary;
    //    border: 2px solid $w3-color-tertiary;
    //    color: $white;
    //
    //    &:hover {
    //        background: lighten($w3-color-tertiary,10%);
    //        border-color: lighten($w3-color-tertiary,10%);
    //        color: $white;
    //    }
    //}
    //
    //&--tertiary-outline {
    //    background: none;
    //    border: 2px solid $w3-color-tertiary;
    //    color: $w3-color-tertiary;
    //
    //    &:hover {
    //        background: #ffffff;
    //        color: lighten($w3-color-tertiary,10%);
    //        border-color: lighten($w3-color-tertiary,10%);
    //    }
    //}

    &--white {
        background: white;
        border: 2px solid white;
        color: $w3-color-primary;

        &:hover {
            background: lighten($w3-color-tertiary,10%);
            border-color: lighten($w3-color-tertiary,10%);
            color: lighten($w3-color-primary,10%);
        }
    }

    &--light-grey {
        background: white;
        border: 1px solid #7373733d;
        font-weight: normal;

        &:hover {
            background-color: white;
            color: $w3-color-primary;
            border-color: $w3-color-primary;
        }
    }

    &--text-light {
        color: grey;
    }

    &--link {
        &-primary {
            .fal, .far, .fas {
                vertical-align: middle;
            }

            color: $w3-color-primary;
            background: none !important;
            &:hover {
                color: lighten($w3-color-primary,10%);
            }
        }
        &-secondary{
            .fal, .far, .fas {
                vertical-align: middle;
            }

            color: $w3-color-secondary;
            background: none !important;
            &:hover {
                color: lighten($w3-color-secondary,10%);
            }
        }


    }

    &--small {
        font-size: 0.85rem;
    }
    &--smallest {
        font-size: 0.7rem;
    }

    &--padding-large {
        padding: 8px 16px;
    }

    &--biggest {
        font-size: 1.7rem;
        padding: 7px 20px;
    }
}
