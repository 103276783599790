.w3-dropdown {

    @include mobile {
        display: block;
        width: 100%;
    }

    // hover dropdown
    &.is-hoverable {
        &:hover, &:focus, &:active {
            .w3-dropdown-menu { display: block; }
        }
    }

    // trigger button
    &-trigger {
        .w3-button {
            padding: 8px 16px;

            &:hover,
            &--active {
                background-color: $w3-color-primary;
                color: #fff;
            }

            @include mobile {
                width: 100%;
            }
        }
    }

    .dropdown-menu {
        min-width: 20rem;

        @include mobile {
            position: relative;
        }
    }
    &-content {
        padding: 15px;
        border-radius: 7px;
        max-height: 50vh;
        overflow-x: scroll;

        &__close-btn {
            cursor: pointer;
            position: absolute;
            right: 15px;
            top: 18px;
            font-size: 1.2rem;
        }
    }
}
